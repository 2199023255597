module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-181770922-1"]},
    },{
      plugin: require('../../../themes/gatsby-theme-youtube/gatsby-browser.js'),
      options: {"plugins":[],"apiKey":"AIzaSyDNp1LyMB94M0lCnF5OxTKSD-dWgKE8IiU","channelId":"UCpi2S8wW4xLlUCVryhyBtsA","trackingId":"UA-181770922-1","basePath":"/","logo":"logo.png","favicon":"static/favicon.png","navData":{"leftNavItems":[],"rightNavItems":[{"name":"About","type":"internal","link":"/about"},{"name":"More from Us","dropDown":[{"name":"Factly Stories","link":"https://factly.in","type":"external"},{"name":"Data Dashboards","link":"https://dashboards.factly.in","type":"external"},{"name":"Counting India","link":"https://countingindia.com","type":"external"}]}]},"bannerData":[{"name":"Decode Season 1","icon":"decode.jpg","playlistId":"PLEQcsVYyf3ICmFn7ZP00y362M2YdPHwHt"},{"name":"Decode Lite","icon":"decode-lite.jpg","playlistId":"PLEQcsVYyf3IBlzW5qPaozJZRKeS-aFpfv"},{"name":"Pause","icon":"pause.jpg","playlistId":"PLEQcsVYyf3IDpDYZ_Y-fuvSgYIY3TyBLv"}],"footer":"Factly"},
    }]
